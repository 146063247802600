import React from "react";
import { Input } from "../../../tabin/components/input";
import * as yup from "yup";
import { useCart } from "../../../context/cart-context";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const emailSchema = yup.string().email("Please enter a valid email address").required("Please enter an email address");
const firstNameSchema = yup.string().required("Please enter your first name");
const lastNameSchema = yup.string().required("Please enter your last name");
const phoneNumberSchema = yup
    .string()
    // .matches(phoneRegExp, "Please enter a valid phone number")
    .required("Please enter a phone number in case we need to contact you regarding your order");

export const GuestInformation = () => {
    const { guestInformation, setGuestInformation } = useCart();

    const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGuestInformation({ ...guestInformation, email: event.target.value, emailError: "" });
    };

    const onChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGuestInformation({ ...guestInformation, firstName: event.target.value, firstNameError: "" });
    };

    const onChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGuestInformation({ ...guestInformation, phoneNumber: event.target.value, phoneNumberError: "" });
    };

    const title = (
        <div className="title mb-1 mt-3">
            <div className="h3">Customer Information</div>
            <div className="separator-2"></div>
        </div>
    );

    const onBlurEmail = async () => {
        try {
            await emailSchema.validate(guestInformation.email);
            setGuestInformation({ ...guestInformation, emailError: "" });
        } catch (e) {
            setGuestInformation({ ...guestInformation, emailError: e.errors[0] });
        }
    };

    const onBlurFirstName = async () => {
        try {
            await firstNameSchema.validate(guestInformation.firstName);
            setGuestInformation({ ...guestInformation, firstNameError: "" });
        } catch (e) {
            setGuestInformation({ ...guestInformation, firstNameError: e.errors[0] });
        }
    };

    const onBlurPhoneNumber = async () => {
        try {
            await phoneNumberSchema.validate(guestInformation.phoneNumber);
            setGuestInformation({ ...guestInformation, phoneNumberError: "" });
        } catch (e) {
            setGuestInformation({ ...guestInformation, phoneNumberError: e.errors[0] });
        }
    };

    const emailInput = (
        <>
            <Input
                name="email"
                // label="Email"
                placeholder="Email"
                type="text"
                onChange={onChangeEmail}
                onBlur={onBlurEmail}
                value={guestInformation.email}
                error={guestInformation.emailError}
            />
        </>
    );

    const firstNameInput = (
        <>
            <Input
                name="firstName"
                // label="First Name"
                placeholder="Name"
                type="text"
                onChange={onChangeFirstName}
                onBlur={onBlurFirstName}
                value={guestInformation.firstName}
                error={guestInformation.firstNameError}
            />
        </>
    );

    const phoneNumberInput = (
        <>
            <Input
                name="phoneNumber"
                // label="First Name"
                placeholder="Phone Number"
                type="tel"
                onChange={onChangePhoneNumber}
                onBlur={onBlurPhoneNumber}
                value={guestInformation.phoneNumber}
                error={guestInformation.phoneNumberError}
            />
        </>
    );

    return (
        <>
            <div>
                {title}
                {/* <Title2Font>Sign up</Title2Font> */}
                {/* <div className="mt-4"></div> */}
                {/* {serverError && <div className="text-error mb-3">{serverError}</div>} */}
                {firstNameInput}
                <div className="mb-1"></div>
                {emailInput}
                <div className="mb-1"></div>
                {phoneNumberInput}
            </div>
        </>
    );
};
