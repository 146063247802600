import { NoMatch } from "./page/error/404";
import Unauthorised from "./page/error/unauthorised";
import Modal from "react-modal";
import { Login } from "./page/auth/login";
import { Checkout } from "./page/checkout";
import { Route, Routes } from "react-router";

import "react-toastify/dist/ReactToastify.min.css";
import { Logger } from "aws-amplify";
import { ToastContainer } from "../tabin/components/toast";
import { RestaurantList } from "./page/restaurantList";
import { Logout } from "./page/auth/logout";
import { BrowserRouter } from "react-router-dom";
import { OrderPlaced } from "./page/orderPlaced";
import { Restaurant } from "./page/restaurant";

let electron: any;
let ipcRenderer: any;
try {
    electron = window.require("electron");
    ipcRenderer = electron.ipcRenderer;
} catch (e) {}

const logger = new Logger("Main");

Modal.setAppElement("#root");

// Auth routes
export const loginPath = "/login";
export const restaurantListPath = "/";
export const restaurantPath = "/restaurant";
export const checkoutPath = "/checkout";
export const orderPlacedPath = "/order_placed";
export const logoutPath = "/log_out";
export const unauthorizedPath = "/unauthorized";

export default () => {
    return (
        <>
            <BrowserRouter>
                <AppRoutes />
            </BrowserRouter>
            <ToastContainer />
        </>
    );
};

const AppRoutes = () => {
    return (
        <Routes>
            <Route path={loginPath} element={<Login />} />
            <Route path={logoutPath} element={<Logout />} />
            <Route path={restaurantListPath} element={<RestaurantList />} />
            <Route path={checkoutPath} element={<Checkout />} />
            <Route path={`${orderPlacedPath}`} element={<OrderPlaced />} />
            <Route path={`${restaurantPath}/:restaurantId`} element={<Restaurant />} /> {/* Old method */}
            <Route path={`:restaurantId`} element={<Restaurant />} />
            <Route path={unauthorizedPath} element={<Unauthorised />} />
            <Route path="*" element={<NoMatch />} />
        </Routes>
    );
};
