import { useEffect, useState } from "react";
import { add, format, isAfter, isToday, isTomorrow, startOfMinute, addMinutes, subMinutes } from "date-fns";
import { useMutation } from "@apollo/client";
import { Select } from "./select";
import { GET_ONLINE_ORDERS, IGET_ONLINE_ORDERS, IGET_RESTAURANT_OPERATING_HOURS } from "../../graphql/customQueries";
import { getIsRestaurantOpen, getRestaurantTimings } from "../../util/util";
import { IRestaurantTimings } from "../../model/model";
import { useRestaurant } from "../../context/restaurant-context";
import "./orderScheduleDateTime.scss";

const RESTAURANT_TIMINGS_INTERVAL = 15;

interface IAvailableDay {
    dateValue: string;
    dateLabel: string;
    day: number; //Day starts from 0; 0 = Sunday, 1 = Monday, 2 = Tuesday, 3 = Wednesday, 4 = Thursday etc...
    isOpen: boolean;
    isToday: boolean;
    timings: IRestaurantTimings[];
}

interface IAvailableTime {
    dayTime?: Date;
    timeValue: string;
    timeLabel: string;
    totalNumberOfOrdersThresholdPerTimeSlot?: number | null;
    disabled?: boolean;
}

export const OrderScheduleDateTime = (props: IProps) => {
    const { operatingHours, preparationTimeInMinutes } = props;
    const { restaurant } = useRestaurant();

    const [selectedDate, setSelectedDate] = useState("");
    const [selectedTime, setSelectedTime] = useState("");
    const [availableDays, setAvailableDays] = useState<IAvailableDay[]>([]);
    const [availableTimes, setAvailableTimes] = useState<IAvailableTime[]>([]);
    const [selectedDateOrders, setSelectedDateOrders] = useState<string[]>([]);

    const [getOnlineOrders] = useMutation(GET_ONLINE_ORDERS);

    useEffect(() => {
        if (selectedTime === "CLOSED") {
            props.onChange("INVALID");
        } else if (!selectedTime) {
            //ASAP selected
            props.onChange(null);
        } else {
            props.onChange(`${selectedDate}T${selectedTime}`);
        }
    }, [selectedDate, selectedTime]);

    useEffect(() => {
        recalculateAvailableDays();
    }, [operatingHours, preparationTimeInMinutes]);

    useEffect(() => {
        recalculateAvailableTimes();
        refetchOrdersData();
    }, [availableDays, selectedDate]);

    useEffect(() => {
        const interval = setInterval(recalculateAvailableTimes, 1000 * 60); // Every minute

        return () => clearTimeout(interval);
    }, [availableDays]);

    useEffect(() => {
        if (!getEnableThreshold()) return;

        const interval = setInterval(refetchOrdersData, 1000 * 60 * 2); // Every 2 minutes

        return () => clearTimeout(interval);
    }, [selectedDate, operatingHours]);

    useEffect(() => {
        recalculateAvailableTimes();
    }, [selectedDateOrders]);

    const getEnableThreshold = () => {
        const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

        return days.some((day) => operatingHours[day]?.some((timeslot) => timeslot.totalNumberOfOrdersThresholdPerTimeSlot !== null));
    };

    const refetchOrdersData = async () => {
        if (!selectedDate) return;

        try {
            const getOnlineOrdersRes = await getOnlineOrders({
                variables: {
                    restaurantId: restaurant?.id ?? "",
                    scheduledAt: selectedDate,
                },
            });

            const onlineOrders = getOnlineOrdersRes.data.getOnlineOrders.orders;
            recalculateThresholds(onlineOrders);
        } catch (e) {
            console.error(e);
        }
    };

    const roundDownToNearest15Minutes = (date: Date): Date => {
        const minutes = date.getMinutes();
        const roundedMinutes = Math.floor(minutes / 15) * 15;
        return startOfMinute(addMinutes(subMinutes(date, minutes), roundedMinutes));
    };

    const recalculateThresholds = (orders: IGET_ONLINE_ORDERS[]) => {
        const orderFormatted = orders.map((order) => {
            const scheduledAt = new Date(order.orderScheduledAt ?? order.placedAt);
            const scheduledAtRounded = roundDownToNearest15Minutes(scheduledAt);
            return format(scheduledAtRounded, "HH:mm:ss");
        });

        setSelectedDateOrders(orderFormatted);
    };

    const recalculateAvailableDays = () => {
        const now = new Date();
        const nextDays: IAvailableDay[] = [];
        let firstAvailableDate = "";

        for (let i = 0; i < 8; i++) {
            const selectedDate = add(now, { days: i });
            const day = selectedDate.getDay();
            const isDateToday = isToday(selectedDate);

            const dateValue = format(selectedDate, "yyyy-MM-dd");
            let dateLabel = isDateToday ? "Today" : isTomorrow(selectedDate) ? "Tomorrow" : format(selectedDate, "eee, do MMM");

            const isOpen = getIsRestaurantOpen(operatingHours, day);
            if (!isOpen) dateLabel += " (CLOSED)";

            if (isOpen && !firstAvailableDate) firstAvailableDate = dateValue;

            nextDays.push({
                dateValue,
                dateLabel,
                day,
                isOpen,
                isToday: isDateToday,
                timings: getRestaurantTimings(operatingHours, selectedDate, day, RESTAURANT_TIMINGS_INTERVAL),
            });
        }

        setAvailableDays(nextDays);
        setSelectedDate(firstAvailableDate);
    };

    const recalculateAvailableTimes = () => {
        let availTimes: IAvailableTime[] = [];
        let foundMatchingTimeAsBefore = false;

        let nextAvailDateTime = new Date();
        if (preparationTimeInMinutes) {
            nextAvailDateTime = addMinutes(nextAvailDateTime, preparationTimeInMinutes);
        }

        availableDays.forEach((day) => {
            if (day.dateValue === selectedDate) {
                let dayTimings = day.timings;

                dayTimings.forEach((t) => {
                    if (isAfter(t.interval, nextAvailDateTime)) {
                        const timeValue = format(t.interval, "HH:mm:ss"); // HH = 24hour, hh = 12hour
                        const timeLabel = format(t.interval, "hh:mm a");

                        const aTime: IAvailableTime = {
                            dayTime: t.interval,
                            timeValue,
                            timeLabel,
                            totalNumberOfOrdersThresholdPerTimeSlot: t.totalNumberOfOrdersThresholdPerTimeSlot,
                            disabled: false,
                        };

                        if (aTime.totalNumberOfOrdersThresholdPerTimeSlot) {
                            selectedDateOrders.forEach((orderTimeValue) => {
                                if (timeValue === orderTimeValue && aTime.totalNumberOfOrdersThresholdPerTimeSlot) {
                                    aTime.totalNumberOfOrdersThresholdPerTimeSlot -= 1;
                                }
                            });

                            if (aTime.totalNumberOfOrdersThresholdPerTimeSlot > 0) {
                                availTimes.push(aTime);
                                if (!foundMatchingTimeAsBefore && selectedTime === timeValue) {
                                    foundMatchingTimeAsBefore = true;
                                }
                            }
                        } else {
                            availTimes.push(aTime);

                            if (!foundMatchingTimeAsBefore && selectedTime == timeValue) {
                                foundMatchingTimeAsBefore = true;
                            }
                        }
                    }
                });

                if (day.isToday && availTimes.length === 0) {
                    availTimes.unshift({ timeValue: "CLOSED", timeLabel: "CLOSED", disabled: true });
                }
            }
        });

        setAvailableTimes(availTimes);

        if (availTimes.length > 0 && !foundMatchingTimeAsBefore) {
            setSelectedTime(availTimes[0].timeValue);
        }
    };

    const onChangeDate = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedDate(event.target.value);
    };

    const onChangeTime = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedTime(event.target.value);
    };

    return (
        <div className="order-schedule-date-time-container">
            <div className="order-schedule-date-container">
                <Select name="order-schedule-date" value={selectedDate} onChange={onChangeDate}>
                    {availableDays.map((day) => (
                        <option key={day.dateValue} value={day.dateValue} label={day.dateLabel} disabled={!day.isOpen}>
                            {day.dateLabel}
                        </option>
                    ))}
                </Select>
            </div>
            <div className="mr-1"></div>
            <div className="order-schedule-time-container">
                <Select name="order-schedule-time" value={selectedTime} onChange={onChangeTime}>
                    {availableTimes.map((time) => (
                        <option
                            key={time.timeValue}
                            value={time.timeValue}
                            label={`${time.timeLabel} ${
                                time.totalNumberOfOrdersThresholdPerTimeSlot ? `(${time.totalNumberOfOrdersThresholdPerTimeSlot} Orders)` : ""
                            }`}
                            disabled={time.disabled}
                        >
                            {time.timeLabel}
                        </option>
                    ))}
                </Select>
            </div>
        </div>
    );
};

interface IProps {
    onChange: (dateTimeLocalISO: string | null) => void;
    operatingHours: IGET_RESTAURANT_OPERATING_HOURS;
    preparationTimeInMinutes: number;
}
