import { useNavigate } from "react-router-dom";
import { FullScreenSpinner } from "../../tabin/components/fullScreenSpinner";
import { Button } from "../../tabin/components/button";

import "./restaurantList.scss";
import { useListRestaurantsQuery } from "../../hooks/useGetRestaurantsQuery";
import { useCart } from "../../context/cart-context";

export const RestaurantList = () => {
    const navigate = useNavigate();
    const { data, error, loading } = useListRestaurantsQuery();
    const { products } = useCart();

    // useEffect(() => {
    //         const cameFromCheckoutPage = sessionStorage.getItem("cameFromCheckoutPage");

    //         if (cameFromCheckoutPage == "true" && products && products.length > 0) {
    //             history.replace("/checkout");
    //         }
    // }, []);

    if (loading) {
        return <FullScreenSpinner show={true} text="Loading restaurants" />;
    }

    if (error) {
        return <div>Error! {error.message}</div>;
    }

    const onOrder = (restaurantId: string) => {
        navigate(`/${restaurantId}`);
    };

    return (
        <>
            <div className="restaurant-list">
                <div className="h2 mb-6">Select a restaurant to access </div>
                {data &&
                    data
                        .filter((r) => r.verified === true)
                        .sort((a, b) => (a.name > b.name && 1) || -1)
                        .map((restaurant, index) => (
                            <div key={restaurant.id}>
                                {index != 0 && <div className="separator-4"></div>}
                                <div className="restaurant-list-item">
                                    <div>{restaurant.name}</div>
                                    <Button
                                        onClick={() => {
                                            onOrder(restaurant.id);
                                        }}
                                    >
                                        Order
                                    </Button>
                                </div>
                            </div>
                        ))}
            </div>
        </>
    );
};
